var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"applyInvoice"},[_c('div',{staticClass:"top_box",style:({
            background:
                'url(' +
                require('@/assets/bg2.png') +
                ') top left no-repeat #ffffff',
            backgroundSize: '100% ',
        })},[_c('p',{staticClass:"top_title"},[_vm._m(0),_c('span',[_c('el-button',{staticStyle:{"background":"#fff","color":"#2370eb","border-color":"#2370eb","margin-right":"20px"},attrs:{"size":"mini","type":"primary","icon":"el-icon-arrow-left"},on:{"click":function($event){return _vm.onClose()}}},[_vm._v("返回列表")])],1)]),_c('div',{staticClass:"main_box"},[_c('el-form',{ref:"form",staticStyle:{"padding-top":"15px"},attrs:{"label-position":"left","size":"small","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"费用类型","required":""}},[_c('el-select',{staticStyle:{"width":"240px"},attrs:{"size":"small","placeholder":"请选择"},model:{value:(_vm.form.declareType),callback:function ($$v) {_vm.$set(_vm.form, "declareType", $$v)},expression:"form.declareType"}},[_c('el-option',{attrs:{"label":"订单销售费用","value":1}}),_c('el-option',{attrs:{"label":"月度报销费用","value":2}})],1)],1),(_vm.form.declareType == 2)?_c('el-form-item',[_c('div',[_vm._v("本月剩余可报销额度："+_vm._s(_vm.expenseAmount))])]):_vm._e(),_c('el-form-item',{attrs:{"label":"客户","required":""}},[_c('el-select',{staticStyle:{"width":"240px"},attrs:{"filterable":"","size":"small","filter-method":_vm.conListFilter,"placeholder":"请输入客户名称搜索选择","clearable":""},on:{"change":function () {
                                _vm.getContract();
                                _vm.getOrderList();
                            }},model:{value:(_vm.form.customerId),callback:function ($$v) {_vm.$set(_vm.form, "customerId", $$v)},expression:"form.customerId"}},_vm._l((_vm.customerList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.companyName,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.isContract ? '选择合同' : '销售订单',"required":_vm.form.declareType == 1}},[_c('div',{staticStyle:{"position":"relative"}},[(_vm.isContract)?_c('el-cascader',{ref:"cascader",staticStyle:{"width":"240px"},attrs:{"size":"small","options":_vm.contractList,"show-all-levels":false,"clearable":"","props":{
                                expandTrigger: 'hover',
                                emitPath: false,
                                value: 'id',
                                label: 'contractNo',
                                children: 'contractTargetBOList',
                            }},on:{"change":_vm.chooseContract},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var data = ref.data;
return [_c('div',{on:{"click":function($event){return _vm.cascaderClick(data)}}},[_c('span',[_vm._v(_vm._s(data.contractNo))])])]}}],null,false,451199808),model:{value:(_vm.form.contractTargetId),callback:function ($$v) {_vm.$set(_vm.form, "contractTargetId", $$v)},expression:"form.contractTargetId"}}):_c('el-select',{staticStyle:{"width":"240px"},attrs:{"size":"small","filterable":"","filter-method":_vm.getOrderList,"placeholder":"请选择或输入搜索","clearable":""},on:{"change":_vm.chooseOrder},model:{value:(_vm.form.orderId),callback:function ($$v) {_vm.$set(_vm.form, "orderId", $$v)},expression:"form.orderId"}},_vm._l((_vm.orderList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.orderNo,"value":item.id}})}),1),_c('p',{staticClass:"other_toast",on:{"click":function () {
                                    _vm.isContract = !_vm.isContract;
                                    _vm.singleData = {};
                                    if (_vm.isContract) {
                                        _vm.form.orderId = '';
                                    } else {
                                        _vm.form.contractTargetId = '';
                                    }
                                    _vm.estimateAmount = {};
                                    _vm.form.amount = '';
                                }}},[_vm._v(" "+_vm._s(_vm.isContract ? '未查到合同？点击切换为选择销售订单' : '未查到销售订单？点击切换为选择合同')+" ")])],1)]),(_vm.singleData.id)?_c('el-form-item',[_c('div',{staticClass:"other_info"},[_c('p',[_c('span',[_vm._v("产品类别")]),_c('span',[_vm._v(_vm._s(_vm.isContract ? _vm.singleData.contractNo : _vm.returnProductName() || '- -'))])]),_c('p',[_c('span',[_vm._v("版本")]),_c('span',[_vm._v(_vm._s(_vm.returnText('version')))])]),_c('p',[_c('span',[_vm._v("购买类型")]),_c('span',[_vm._v(_vm._s(_vm.isContract ? _vm.returnText('productType') : _vm.returnText('productType', 'orderType')))])]),_c('p',[_c('span',[_vm._v("用户数")]),_c('span',[_vm._v(_vm._s(_vm.isContract ? _vm.singleData.number : _vm.singleData.shouldNumber || '- -'))])]),_c('p',[_c('span',[_vm._v("年限")]),_c('span',[_vm._v(_vm._s(_vm.isContract ? _vm.singleData.term : _vm.singleData.shouldTerm || '- -'))])]),_c('p',[_c('span',[_vm._v("金额")]),_c('span',[_vm._v(_vm._s(_vm.singleData.receivableAmount || '- -'))])])])]):_vm._e(),_c('el-form-item',{attrs:{"label":"申报金额","required":""}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"size":"small","placeholder":"请输入"},on:{"input":_vm.blurAmount},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1),(
                        _vm.form.declareType == 1 &&
                        (_vm.estimateAmount.proportion ||
                            _vm.estimateAmount.achievement)
                    )?_c('el-form-item',[_c('div',{staticClass:"other_info"},[_c('p',[_c('span',[_vm._v("结算价")]),_c('span',[_vm._v(_vm._s(_vm.discount))])]),_c('p',[_c('span',[_vm._v("业绩系数")]),_c('span',[_vm._v(_vm._s(_vm.estimateAmount.proportion)+"%")])]),_c('p',[_c('span',[_vm._v("预估业绩")]),_c('span',[_vm._v(_vm._s(parseInt(_vm.estimateAmount.achievement)))])])])]):_vm._e(),(
                        (_vm.estimateAmount.isShow === true &&
                            _vm.form.declareType == 1) ||
                        (_vm.estimateAmount.isShow === true &&
                            _vm.form.amount > 1 &&
                            _vm.form.declareType == 1 &&
                            _vm.btnP.special)
                    )?_c('el-form-item',{attrs:{"label":"申请特批","required":""}},[_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},model:{value:(_vm.form.special),callback:function ($$v) {_vm.$set(_vm.form, "special", $$v)},expression:"form.special"}})],1):_vm._e()],1)],1)]),_c('div',{staticClass:"bnt"},[_c('el-button',{staticStyle:{"background":"none","border-color":"#d5d6df","color":"#666666","margin-left":"auto"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"background":"#2370eb","border-color":"#2370eb","color":"#fff","margin-right":"30px"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onSubmit}},[_vm._v("确定")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"iconfont icon-feiyongshenqing"}),_vm._v(" 销售费用申报")])}]

export { render, staticRenderFns }