<template>
    <div class="applyInvoice">
        <div
            :style="{
                background:
                    'url(' +
                    require('@/assets/bg2.png') +
                    ') top left no-repeat #ffffff',
                backgroundSize: '100% ',
            }"
            class="top_box"
        >
            <p class="top_title">
                <span>
                    <i class="iconfont icon-feiyongshenqing"></i>
                    销售费用申报</span
                >
                <span>
                    <el-button
                        @click="onClose()"
                        size="mini"
                        type="primary"
                        icon="el-icon-arrow-left"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                            margin-right: 20px;
                        "
                        >返回列表</el-button
                    ></span
                >
            </p>
            <div class="main_box">
                <el-form
                    ref="form"
                    label-position="left"
                    size="small"
                    label-width="100px"
                    style="padding-top: 15px"
                >
                    <el-form-item label="费用类型" required>
                        <el-select
                            v-model="form.declareType"
                            size="small"
                            style="width: 240px"
                            placeholder="请选择"
                        >
                            <el-option label="订单销售费用" :value="1">
                            </el-option>
                            <el-option label="月度报销费用" :value="2">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="form.declareType == 2">
                        <div>本月剩余可报销额度：{{ expenseAmount }}</div>
                    </el-form-item>
                    <el-form-item label="客户" required>
                        <el-select
                            filterable
                            v-model="form.customerId"
                            size="small"
                            :filter-method="conListFilter"
                            style="width: 240px"
                            placeholder="请输入客户名称搜索选择"
                            clearable
                            @change="
                                () => {
                                    getContract();
                                    getOrderList();
                                }
                            "
                        >
                            <el-option
                                v-for="item in customerList"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        :label="isContract ? '选择合同' : '销售订单'"
                        :required="form.declareType == 1"
                    >
                        <div style="position: relative">
                            <el-cascader
                                v-if="isContract"
                                size="small"
                                ref="cascader"
                                v-model="form.contractTargetId"
                                :options="contractList"
                                style="width: 240px"
                                :show-all-levels="false"
                                clearable
                                @change="chooseContract"
                                :props="{
                                    expandTrigger: 'hover',
                                    emitPath: false,
                                    value: 'id',
                                    label: 'contractNo',
                                    children: 'contractTargetBOList',
                                }"
                            >
                                <template slot-scope="{ data }">
                                    <div @click="cascaderClick(data)">
                                        <span>{{ data.contractNo }}</span>
                                    </div>
                                </template>
                            </el-cascader>
                            <!-- <el-select
                                v-if=""
                                style="width: 240px"
                                v-model="form.contractTargetId"
                                :filter-method="getContract"
                                filterable
                                size="small"
                                placeholder="请选择或输入搜索"
                                clearable
                                @change="chooseContract"
                            >
                                <el-option
                                    v-for="item in contractList"
                                    :key="item.id"
                                    :label="item.contractNo"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select> -->
                            <el-select
                                v-else
                                style="width: 240px"
                                v-model="form.orderId"
                                size="small"
                                filterable
                                :filter-method="getOrderList"
                                placeholder="请选择或输入搜索"
                                clearable
                                @change="chooseOrder"
                            >
                                <el-option
                                    v-for="item in orderList"
                                    :key="item.id"
                                    :label="item.orderNo"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <p
                                @click="
                                    () => {
                                        isContract = !isContract;
                                        singleData = {};
                                        if (isContract) {
                                            form.orderId = '';
                                        } else {
                                            form.contractTargetId = '';
                                        }
                                        estimateAmount = {};
                                        form.amount = '';
                                    }
                                "
                                class="other_toast"
                            >
                                {{
                                    isContract
                                        ? '未查到合同？点击切换为选择销售订单'
                                        : '未查到销售订单？点击切换为选择合同'
                                }}
                            </p>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="singleData.id">
                        <div class="other_info">
                            <p>
                                <span>产品类别</span>
                                <span>{{
                                    isContract
                                        ? singleData.contractNo
                                        : returnProductName() || '- -'
                                }}</span>
                            </p>
                            <p>
                                <span>版本</span>
                                <span>{{ returnText('version') }}</span>
                                <!-- singleData.version == 1
                                        ? '老版'
                                        : '新版' -->
                            </p>
                            <p>
                                <span>购买类型</span>
                                <span>{{
                                    isContract
                                        ? returnText('productType')
                                        : returnText('productType', 'orderType')
                                }}</span>
                            </p>
                            <p>
                                <span>用户数</span>
                                <span>{{
                                    isContract
                                        ? singleData.number
                                        : singleData.shouldNumber || '- -'
                                }}</span>
                            </p>
                            <p>
                                <span>年限</span>
                                <span>{{
                                    isContract
                                        ? singleData.term
                                        : singleData.shouldTerm || '- -'
                                }}</span>
                            </p>
                            <p>
                                <span>金额</span>
                                <span>{{
                                    singleData.receivableAmount || '- -'
                                }}</span>
                            </p>
                        </div>
                    </el-form-item>
                    <el-form-item label="申报金额" required>
                        <el-input
                            v-model="form.amount"
                            size="small"
                            style="width: 240px"
                            placeholder="请输入"
                            @input="blurAmount"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        v-if="
                            form.declareType == 1 &&
                            (estimateAmount.proportion ||
                                estimateAmount.achievement)
                        "
                    >
                        <div class="other_info">
                            <p>
                                <span>结算价</span>
                                <span>{{ discount }}</span>
                            </p>
                            <p>
                                <span>业绩系数</span>
                                <span>{{ estimateAmount.proportion }}%</span>
                            </p>
                            <p>
                                <span>预估业绩</span>
                                <span>{{
                                    parseInt(estimateAmount.achievement)
                                }}</span>
                            </p>
                        </div>
                    </el-form-item>
                    <el-form-item
                        label="申请特批"
                        required
                        v-if="
                            (estimateAmount.isShow === true &&
                                form.declareType == 1) ||
                            (estimateAmount.isShow === true &&
                                form.amount > 1 &&
                                form.declareType == 1 &&
                                btnP.special)
                        "
                    >
                        <el-checkbox
                            :true-label="1"
                            :false-label="0"
                            v-model="form.special"
                        ></el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="bnt">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { declareAdd, getAdminSaleAmount } from '@/api/cost/declare.js';
import { customerList } from '@/api/customer/customer.js';
import { customerId } from '@/api/user/ae/customer.js';
// import { customerList } from '@/api/report/report.js';
import { contracGet } from '@/api/contr/contr.js';
import { declareList } from '@/api/cost/declare.js';
import { orderList } from '@/api/order/order.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                contractTargetId: '',
                declareType: '',
                amount: '',
            },
            declareDetailList: [
                {
                    tax: 1,
                    declareDetailAttachmentDOList: [],
                },
            ],
            businessDeclareId: '',
            userType: sessionStorage.getItem('userType'),
            customerList: [],
            contractList: [],
            contractMoney: '',
            allMoney: 0,
            loading: false,
            action: this.$url.url() + '/file/local/upload/contract',
            file: {},
            dialogImageUrl: '',
            dialogVisible: false,
            actualTotalAmount: 0,
            isContract: false,
            orderList: [],
            oneContract: {},
            singleData: {},
            priceMap: {},
            discount: 0,
            estimateAmount: {},
            btnP: {},
            expenseAmount: 0,
        };
    },
    components: {},
    created() {},
    mounted() {
        this.getCustomerList();
        var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
        let btn = {};
        for (let i in btnArr) {
            btn['' + btnArr[i].route] = true;
        }
        this.btnP = btn;
        this.getAdminSaleAmount();
    },
    methods: {
        getAdminSaleAmount() {
            let data = {
                param: {
                    id: sessionStorage.getItem('adminId'),
                },
            };
            getAdminSaleAmount(data).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    this.expenseAmount = res.data.expenseAmount;
                }
            });
        },
        // 客户列表
        getCustomerList(name) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (name) {
                data.param.name = name;
            }
            customerList(data).then((res) => {
                if (res.code == 200) {
                    this.customerList = res.data.list;
                }
            });
        },
        conListFilter(val) {
            this.getCustomerList(val);
        },
        chooseContract(val) {
            this.singleData = {};
            this.estimateAmount = {};
            this.form.amount = '';
            this.getContract();
        },
        chooseOrder(val) {
            if (val) {
                this.singleData = this.orderList.find((item) => {
                    return item.id == val;
                });
                if (this.returnProductName() == '腾讯企业邮箱') {
                    this.getAchievementTm(
                        this.singleData.shouldNumber,
                        this.singleData.shouldTerm
                    );
                } else if (this.returnProductName() == '阿里企业邮箱') {
                    this.getAchievementAli(
                        this.singleData.shouldNumber,
                        this.singleData.shouldTerm
                    );
                } else if (this.returnProductName() == '网易企业邮箱') {
                    this.getAchievementWy(
                        this.singleData.shouldNumber,
                        this.singleData.shouldTerm
                    );
                }
            } else {
                this.singleData = {};
                this.estimateAmount = {};
                this.form.amount = '';
            }
        },

        // 合同
        getContract(name) {
            if (!this.form.customerId) {
                return this.$message.error('请先选择客户');
            }
            let data = {
                param: {
                    customerId: this.form.customerId,
                    statusList: [10, 12, 8, 7, 11],
                    returnStatus: 2,
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 15,
            };
            if (name) {
                data.param.name = name;
            }
            customerId(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    res.data.list.forEach((item) => {
                        item.contractTargetBOList &&
                        item.contractTargetBOList.length > 0
                            ? item.contractTargetBOList.forEach((itm) => {
                                  itm.contractNo =
                                      itm.productAttributeRelationBOList &&
                                      itm.productAttributeRelationBOList
                                          .length > 0
                                          ? itm
                                                .productAttributeRelationBOList[0]
                                                .productName
                                          : '';
                              })
                            : '- -';
                    });

                    let obj = {
                        param: {
                            customerId: this.form.customerId,
                            adminId: sessionStorage.getItem('adminId'),
                            statusList: [1, 2, 3, 4, 11],
                        },
                    };
                    declareList(obj).then((result) => {
                        if (result.data.total != 0) {
                            res.data.list.forEach((item, index) => {
                                item.contractTargetBOList.forEach((i, idx) => {
                                    if (
                                        i.productCategory != 1 &&
                                        i.productCategory != 2 &&
                                        i.productCategory != 4
                                    ) {
                                        item.contractTargetBOList.splice(
                                            idx,
                                            1
                                        );
                                        return false;
                                    }
                                    result.data.list.forEach((itm) => {
                                        if (i.id == itm.contractTargetId) {
                                            item.contractTargetBOList.splice(
                                                idx,
                                                1
                                            );
                                            return false;
                                        }
                                    });
                                });
                                if (item.contractTargetBOList.length == 0) {
                                    res.data.list.splice(index, 1);
                                }
                                // console.log(item.contractTargetBOList.length);
                            });
                        }
                        // console.log(res.data.list);
                        this.contractList = JSON.parse(
                            JSON.stringify(res.data.list)
                        );
                    });
                    // this.contractList = res.data.list;
                }
            });
        },
        getOrderList(name) {
            if (!this.form.customerId) {
                return this.$message.error('请先选择客户');
            }
            let data = {
                param: {
                    customerId: this.form.customerId,
                    adminId: sessionStorage.getItem('adminId'),
                    confirm: 1,
                },
                pageNum: 1,
                pageSize: 15,
            };
            if (name) {
                data.param.name = name;
            }
            orderList(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.total != 0) {
                        let obj = {
                            param: {
                                customerId: this.form.customerId,
                                adminId: sessionStorage.getItem('adminId'),
                                statusList: [4, 11],
                            },
                        };
                        declareList(obj).then((result) => {
                            if (result.data.total != 0) {
                                res.data.list.forEach((item, index) => {
                                    result.data.list.forEach((itm) => {
                                        if (
                                            item.contractTargetId ==
                                            itm.contractTargetId
                                        ) {
                                            res.data.list.splice(index, 1);
                                        }
                                    });
                                });
                            }
                            this.orderList = JSON.parse(
                                JSON.stringify(res.data.list)
                            );
                        });
                    }
                }
            });
        },
        cascaderClick(nodeData) {
            // console.log(nodeData);
            // console.log(!nodeData.contractTargetBOList);
            if (!nodeData.contractTargetBOList) {
                console.log(nodeData);
                this.singleData = nodeData || {};
                this.form.contractTargetId = nodeData.id;
                // this.adminId = '';
                // this.departmentId = nodeData.id;
                // this.getUserList(nodeData.id);
                // this.$emit('searchData', {
                //     departmentId: nodeData.id,
                //     adminId: this.adminId,
                // });
                this.$refs.cascader.checkedValue = nodeData.contractNo;
                this.$refs.cascader.computePresentText();
                this.$refs.cascader.toggleDropDownVisible(false);
                if (nodeData.contractNo == '腾讯企业邮箱') {
                    this.getAchievementTm(nodeData.number, nodeData.term);
                } else if (nodeData.contractNo == '阿里企业邮箱') {
                    this.getAchievementAli(nodeData.number, nodeData.term);
                } else if (nodeData.contractNo == '网易企业邮箱') {
                    this.getAchievementWy(nodeData.number, nodeData.term);
                }
            }
        },
        getAchievementTm(number, term) {
            this.$Axios({
                url: `/sum?account=${number}&year=${term}`,
                method: 'get',
            }).then((res) => {
                console.log(res);
                // this.aliPriceMap = res.data.aliPriceMap;
                // this.neteasePriceMap = res.data.neteasePriceMap;
                this.priceMap = res.qqPriceMap;
                this.priceMap.total = this.priceMap.yearPrice * term;
                if (number < 100) {
                    res.qqDiscountPriceList.forEach((item, index) => {
                        switch (index) {
                            case 0:
                                item.discountPrice = item.sumPrice;
                                item.discountYearsPrice = parseFloat(
                                    (item.discountPrice / 3).toFixed(2)
                                );
                                item.discountYearsNumPrice = parseFloat(
                                    (item.discountPrice / 3 / number).toFixed(2)
                                );
                                break;
                            case 1:
                                item.discountPrice = (item.sumPrice * 9) / 10;
                                item.discountYearsPrice = parseFloat(
                                    (item.discountPrice / 4).toFixed(2)
                                );
                                item.discountYearsNumPrice = parseFloat(
                                    (item.discountPrice / 4 / number).toFixed(2)
                                );
                                break;
                            case 2:
                                item.discountPrice = (item.sumPrice * 8) / 10;
                                item.discountYearsPrice = parseFloat(
                                    (item.discountPrice / 5).toFixed(2)
                                );
                                item.discountYearsNumPrice = parseFloat(
                                    (item.discountPrice / 5 / number).toFixed(2)
                                );
                                break;
                            case 3:
                                item.discountPrice = (item.sumPrice * 7) / 10;
                                item.discountYearsPrice = parseFloat(
                                    (item.discountPrice / 6).toFixed(2)
                                );
                                item.discountYearsNumPrice = parseFloat(
                                    (item.discountPrice / 6 / number).toFixed(2)
                                );
                                break;
                        }
                    });
                }

                // this.discountPriceList = res.qqDiscountPriceList;
                if (number >= 5 && number < 20) {
                    this.priceMap.bossDiscountTotal =
                        (this.priceMap.total * 50) / 100;
                    // this.jyNum = (this.priceMap.total * 65) / 100;
                } else if (number >= 20 && number < 50) {
                    this.priceMap.bossDiscountTotal =
                        (this.priceMap.total * 5) / 10;
                    // this.jyNum = (this.priceMap.total * 6) / 10;
                } else if (number >= 50 && number < 100) {
                    this.priceMap.bossDiscountTotal =
                        (this.priceMap.total * 5) / 10;
                    // this.jyNum = this.priceMap.bossDiscountTotal;
                } else {
                    if (term <= 4) {
                        if (term == 1 || term == 2) {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 85 * 5) / 1000;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 8 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 7 * 5) / 100;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 6 * 5) / 100;
                            }
                        } else {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 8 * 5) / 100;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 7 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 6 * 5) / 100;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 5 * 5) / 100;
                            }
                        }
                    } else {
                        if (term == 5) {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 75 * 5) / 1000;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 6 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 5 * 5) / 100;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 4 * 5) / 100;
                            }
                        } else if (term == 6) {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 7 * 5) / 100;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 55 * 5) / 1000;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 45 * 5) / 1000;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 35 * 5) / 1000;
                            }
                        } else if (term == 7 || term == 8) {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 65 * 5) / 1000;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 5 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 35 * 5) / 1000;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 3 * 5) / 100;
                            }
                        } else {
                            if (number >= 100 && number < 200) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 6 * 5) / 100;
                            } else if (number >= 200 && number < 500) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 4 * 5) / 100;
                            } else if (number >= 500 && number < 1000) {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 3 * 5) / 100;
                            } else {
                                this.priceMap.bossDiscountTotal =
                                    (this.priceMap.total * 3 * 5) / 100;
                            }
                        }
                    }
                }
                this.discount = this.priceMap.bossDiscountTotal;
            });
        },
        getAchievementAli(number, term) {
            this.$Axios({
                url: `/ali?account=${number}&year=${term}`,
                method: 'get',
            }).then((res) => {
                console.log(res);
                this.discount = res.bossTotal;
            });
        },
        getAchievementWy(number, term) {
            this.$Axios({
                url: `/netease?account=${number}&year=${term}`,
                method: 'get',
            }).then((res) => {
                this.discount = res.bossTotal;
            });
        },
        onClose() {
            this.$router.go(-1);
        },
        onSubmit() {
            if (!this.form.declareType) {
                return this.$message.error('请选择费用类型');
            }
            if (!this.form.customerId) {
                return this.$message.error('请选择客户');
            }
            if (
                this.form.declareType == 1 &&
                this.isContract &&
                !this.form.contractTargetId
            ) {
                return this.$message.error('请选择合同标的');
            }
            if (
                this.form.declareType == 1 &&
                !this.isContract &&
                !this.form.orderId
            ) {
                return this.$message.error('请选择销售订单');
            }
            if (!this.form.amount) {
                return this.$message.error('请输入申报金额');
            }
            if (
                !this.form.special &&
                this.estimateAmount.isShow &&
                this.form.declareType == 1 &&
                this.btnP.special
            ) {
                return this.$message.error('请勾选申请特批');
            }
            if (
                this.form.declareType == 2 &&
                this.form.amount > this.expenseAmount
            ) {
                return this.$message.error('申报失败，本月剩余可报销额度不足');
            }
            if (
                this.form.amount > this.discount * 0.2 &&
                this.form.declareType == 1 &&
                !this.form.special
            ) {
                this.estimateAmount.isShow = true;
                // if (!this.form.special) {
                return this.$message.error(`申报失败，销售费用不得超过
                    ${this.isContract ? '合同标的应收金额' : '销售订单结算价'}
                    的20%；请修改申报金额${
                        this.btnP.special ? '或申请特批' : ''
                    }`);
                // }
            }
            if (
                this.estimateAmount.isShow &&
                !this.btnP.special &&
                this.form.declareType == 1
            ) {
                return this.$message.error(
                    `申报失败， 销售折扣不得超过最低折扣权限；请修改申报金额`
                );
            }

            let data = {
                param: {
                    businessDeclareDO: {
                        ...this.form,
                        ratio: this.estimateAmount.proportion,
                        predictAmount: parseInt(
                            this.estimateAmount.achievement
                        ),
                        settleAmount: this.discount,
                    },
                },
            };
            if (this.isContract) {
                delete data.param.businessDeclareDO.orderId;
            }

            if (!this.isContract) {
                delete data.param.businessDeclareDO.contractTargetId;
            }

            declareAdd(data).then((res) => {
                console.log(res);
                if (res.code == 200 && res.data) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        returnText(type, orderType = '') {
            let text = '';
            if (this.singleData.id) {
                this.singleData.productAttributeRelationBOList.forEach(
                    (item) => {
                        if (item.attributeColumnName == type) {
                            item.productAttributeValueBOList.forEach((itm) => {
                                if (orderType) {
                                    if (
                                        itm.attributeValue ==
                                        this.singleData[orderType]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                } else {
                                    if (
                                        itm.attributeValue ==
                                        this.singleData[type]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                }
                            });
                        }
                    }
                );
            }
            return text;
        },
        returnProductName() {
            let text = '';
            if (this.singleData.id) {
                this.singleData.productAttributeRelationBOList.forEach(
                    (item) => {
                        text = item.productName;
                    }
                );
            }
            return text;
        },
        blurAmount() {
            if (
                ((!this.form.contractTargetId && this.isContract) ||
                    (!this.form.orderId && !this.isContract)) &&
                this.form.declareType == 1
            ) {
                return this.$message.error('请先选择合同或者销售订单');
            }
            if (!this.form.amount) {
                return this.$message.error('请输入申报金额');
            }
            console.log(123);
            this.estimateAmount = this.$Calculator.change(
                this.discount,
                this.singleData.receivableAmount,
                this.form.amount,
                this.singleData.number
            );
        },
    },
};
</script>
<style lang="scss" scoped>
.applyInvoice {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top_box {
        padding: 16px 0 0;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .main_box {
        flex: 1;
        padding-top: 27px;
        display: flex;
        justify-content: center;
        .other_toast {
            position: absolute;
            cursor: pointer;
            color: #2370eb;
            right: -250px;
            top: 0;
            user-select: none;
        }
        .other_info {
            width: 240px;
            padding: 14px 17px;
            box-sizing: border-box;
            background: #ffffff;
            border-radius: 10px;
            border: 1px dashed #2370eb;
            // p + p {
            //     margin-top: 16px;
            // }
            p {
                font-size: 14px;
                span:nth-child(1) {
                    display: inline-block;
                    width: 65px;
                    margin-right: 10px;
                    color: #666666;
                }
                span:nth-child(2) {
                    color: #333333;
                }
            }
        }
    }
    .top_title {
        font-size: 16px;
        color: #333333;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .icon-feiyongshenqing {
            color: #2370eb;
            margin-left: 19px;
            margin-right: 13px;
            font-weight: 600;
        }
    }
    .text_box {
        display: flex;
        padding-left: 52px;
        align-items: center;
        p {
            font-size: 14px;
            span:nth-child(1) {
                color: #666666;
                margin-right: 12px;
            }
            span:nth-child(2) {
                color: #333333;
                margin-right: 75px;
            }
        }
    }
    .invoice_box {
        margin-top: 12px;
        border-radius: 1px;
        flex: 1;
        background: #fff;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1;
    }
    .bnt {
        background: #fff;
        padding: 14px 0;
        border-top: 1px solid #e5e5e5;
        text-align: center;
        button {
            padding: 5px 30px;
        }
    }
}
</style>
